import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import PageHeader from "../components/PageHeader";
import Seo from "../components/Seo";

const Feed = ({ location }) => {
  return (
    <Layout>
      <Section>
        <Seo
          title="compAIR - Feed."
          description="RSS, Atom & JSON Feed."
          url={location.pathname}
          section="feed"
        />
        <PageHeader title="Announcement Feeds" />
        <div className="prose text-center mx-auto">
          <ul className="flex flex-col">
            <a className="text-blue-500 hover:text-blue-600" href="/rss.xml">
              RSS Feed
            </a>
            <a className="text-blue-500 hover:text-blue-600" href="/atom.xml">
              Atom Feed
            </a>
            <a className="text-blue-500 hover:text-blue-600" href="/feed.json">
              JSON Feed
            </a>
          </ul>
        </div>
      </Section>
    </Layout>
  );
};

export default Feed;
